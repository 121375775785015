import { registerApp } from '@mfl/framework';
import { currentUser } from '@mfl/platform-shell';

const route = '/v1/layout-editor';

export async function register() {
  if (currentUser.isEntitledTo('template-editor')) {
    await registerApp('layout-editor', route);
  }
}
